@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'Roboto', sans-serif;
    @apply bg-body-background;
}

*:focus {
    outline: none !important;
}

.MuiTable-root {
    background: white;
    border-radius: 20px;
}

tbody .MuiTableRow-root {
    cursor: pointer;

    &:hover {
        background: #fbfbfb;
    }
}

/* Material UI table */
.MuiTableCell-head {
    border-bottom: 1px solid #eaeaea !important;
}

.MuiTableCell-head,
.MuiTableCell-head > * {
    font-weight: 600 !important;
}

.MuiTableHead-root.resizable .MuiTableRow-root .MuiTableCell-head {
    // resize: horizontal;
    // overflow-x: overlay;
    // overflow-y: hidden;
}

.react-datepicker-wrapper {
    width: 100%;
}