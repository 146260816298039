.resourceTable {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border-width: 1px;
    border-style: solid solid none none;  
    border-color: #7a7a7a;

    td {
        padding: 4px 5px;
        text-align: center;
    }

    td,
    th {
        vertical-align: baseline;
        padding: 0 0.5em;
        border-width: 1px;
        border-style: none none solid solid;  
        border-color: #7a7a7a;
        padding: 5px 5px;
    }

    thead {
        th {
            padding: 3px;
            position: sticky;
            
            top: 0;
            z-index: 1;
            width: 25vw;
            min-width: 30px;
        }
        
        th:first-child {
            position: sticky;
            left: 0;
            z-index: 2;
        }
    }

    tbody {

        th {
            font-weight: 100;
            text-align: left;
            font-size: 14px;
            padding-right: 25px;
            position: sticky;
            left: 0;
            z-index: 1;
        }
    }

    caption {
        text-align: left;
        padding: 0.25rem;
        position: sticky;
        left: 0;
    }
}