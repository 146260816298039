@keyframes configure-clockwise {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

body {
    background: #f2f2f2;
}

@keyframes configure-xclockwise {
    0% {
        transform: rotate(45deg);
    }
    25% {
        transform: rotate(-45deg);
    }
    50% {
        transform: rotate(-135deg);
    }
    75% {
        transform: rotate(-225deg);
    }
    100% {
        transform: rotate(-315deg);
    }
}

.spinner-box {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 9999;
}

.configure-border-1 {
    width: 40px;
    height: 40px;
    padding: 3px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8f4444;
    border-radius: 8px;
    animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.configure-border-2 {
    width: 40px;
    height: 40px;
    padding: 3px;
    left: -115px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8f4444;
    transform: rotate(45deg);
    border-radius: 8px;
    animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.configure-core {
    width: 100%;
    height: 100%;
    background-color: #8f4444;
}
