.fc {
    background: white;
    border-radius: 20px;
    padding: 20px;

    .fc-toolbar-chunk {
        .fc-button-primary {
            padding: 5px 10px;
            font-size: 14px;
            background: #813636;
            border: 0;
        
            &:hover {
                background: #8f4444;
            }
        }
    
        .fc-button-primary:disabled {
            background: #515151;
        }
    
        .fc-toolbar-title {
            font-size: 18px;
        }
    }
    
    .fc-col-header-cell-cushion {
        padding: 20px;
        font-weight: 100;
    }

    .fc-day-today {
        background: #ffeeee !important;
    }

    .fc-daygrid-day-number {
        font-size: 14px;
        padding: 10px;
    }
    
    .fc-scrollgrid {
        border-radius: 4px;
    }
}